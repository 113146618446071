<template>
  <div class="faq-page">
    <div class="container">
      <section class="faq-page__section">
        <base-sub-heading class="faq-page__header-sub-heading" align="center">
          <span v-html="`FAQ`" />
        </base-sub-heading>

        <base-heading class="faq-page__header-heading" align="center">
          <span v-html="$t('faq.heading', {userType: $t('author')})" />
        </base-heading>

        <base-accordion
          v-model="faq"
          class="faq-page__accordion"
        >
          <base-accordion-item
            class="faq-page__item"
            v-for="(item, index) in instructorQuestions"
            :key="index"
            :name="item.question"
            :label="`${index + 1}`"
          >
            <p class="faq-page__item-answer">
              {{ item.answer}}
            </p>
          </base-accordion-item>
        </base-accordion>
      </section>

      <section class="faq-page__section">
          <base-sub-heading class="faq-page__header-sub-heading" align="center">
          <span v-html="`FAQ`" />
        </base-sub-heading>

        <base-heading class="faq-page__header-heading" align="center">
          <span v-html="$t('faq.heading', {userType: $t('user')})" />
        </base-heading>

        <base-accordion
          v-model="faq"
          class="faq-page__accordion"
        >
          <base-accordion-item
            class="faq-page__item"
            v-for="(item, index) in userQuestions"
            :key="index"
            :name="item.question"
            :label="`${index + 1}`"
          >
            <p class="faq-page__item-answer">
              {{ item.answer}}
            </p>
          </base-accordion-item>
        </base-accordion>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqPage',
  data: () => ({
    faq: [],
  }),
  computed: {
    instructorQuestions() {
      const questions = this.$t('faq.instructor.questions');
      const answers = this.$t('faq.instructor.answers');

      return questions.map((question, index) => {
        return {
          question: question || '',
          answer: answers[index] ? answers[index] : '',
        };
      });
    },
    userQuestions() {
      const questions = this.$t('faq.stylist.questions');
      const answers = this.$t('faq.stylist.answers');

      return questions.map((question, index) => {
        return {
          question: question || '',
          answer: answers[index] ? answers[index] : '',
        };
      });
    },
  },
};
</script>

<style lang="scss" src="./Faq.scss" />
